import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Star, Users, Shield, ChevronLeft, ChevronRight, Menu, X, HelpCircle, CheckCircle } from 'lucide-react';

const FAQs = [
  { question: "Kasko nedir?", answer: "Kasko, aracınızı çarpma, çizilme, yanma, çalınma gibi risklere karşı koruyan önemli bir sigorta türüdür." },
  { question: "Kasko sigortası ne kadar süreyle geçerlidir?", answer: "Kasko sigortası bir yıl süreyle geçerlidir. Süre bitiminde yenilenebilir." },
  { question: "Kasko sigortası primi nasıl hesaplanır?", answer: "Kasko primi; aracın markası, modeli, yaşı, motor hacmi gibi faktörlerin yanı sıra sürücünün yaşı ve kaza geçmişi gibi kriterlere göre hesaplanır." },
];

const reviews = [
  { name: "Mehmet A.", comment: "Kasko teklifimi hızlıca aldım, fiyatlar çok uygundu." },
  { name: "Zeynep B.", comment: "Aracım için en uygun kasko teklifini buradan buldum." },
  { name: "Ali C.", comment: "Müşteri hizmetleri çok yardımcı oldu, teşekkürler!" },
];

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-xl max-w-md w-full">
        <div className="flex justify-end">
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X size={24} />
          </button>
        </div>
        {children}
      </div>
    </div>
  );
};

export default function KaskoFiyat() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [formData, setFormData] = useState({
    tcno: '',
    plaka: '',
    ruhsat: '',
    telefon: ''
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState({ message: '', isSuccess: false });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('/kasko.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams(formData).toString()
      });
      const data = await response.json();
      if (data.success) {
        setModalContent({
          message: 'Teşekkürler! Kasko teklif isteğiniz başarıyla alındı. En uygun fiyatlarla kısa süre içinde size ulaşacağız.',
          isSuccess: true
        });
        setFormData({tcno: '', plaka: '', ruhsat: '', telefon: ''});
      } else {
        setModalContent({
          message: data.message || 'Bir hata oluştu. Lütfen tekrar deneyiniz.',
          isSuccess: false
        });
      }
      setIsModalOpen(true);
    } catch (error) {
      setModalContent({
        message: 'Bir hata oluştu. Lütfen tekrar deneyiniz.',
        isSuccess: false
      });
      setIsModalOpen(true);
    }
  };

  return (
    <div className="bg-white text-gray-800">
      {/* Header */}
      <header className="bg-white-600 text-black p-4">
        <nav className="container mx-auto flex justify-between items-center">
          <div className="flex items-center">
            <a href="https://www.sigortasatinal.com" aria-label="Anasayfa">
              <img
                src={process.env.PUBLIC_URL + '/logos/centalya.webp'}
                alt="Şirket Logosu"
                className="h-14 mr-6"
              />
            </a>
          </div>
          
          {/* Desktop Menu */}
          <div className="hidden md:flex space-x-4">
            <Link to="/urunler" className="hover:text-red-200">Ürünler</Link>
            <Link to="/hakkimizda" className="hover:text-red-200">Hakkımızda</Link>
            <Link to="/iletisim" className="hover:text-red-200">İletişim</Link>
          </div>
          
          {/* Mobile Menu Button */}
          <button 
            className="md:hidden"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
          </button>
        </nav>

        {/* Mobile Menu */}
        {isMenuOpen && (
          <div className="md:hidden mt-2">
            <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
              <Link to="/urunler" className="block px-3 py-2 rounded-md text-base font-medium hover:bg-white-700">Ürünler</Link>
              <Link to="/hakkimizda" className="block px-3 py-2 rounded-md text-base font-medium hover:bg-white-700">Hakkımızda</Link>
              <Link to="/iletisim" className="block px-3 py-2 rounded-md text-base font-medium hover:bg-white-700">İletişim</Link>
            </div>
          </div>
        )}
      </header>

      {/* Main Content */}
      <div className="container mx-auto px-4 py-8">
  <div className="bg-green-500 bg-opacity-50 rounded-lg p-6 mb-8 flex items-center">
    <img src="/logos/personel.png" alt="Personel" className="w-20 h-20 rounded-full mr-6" />
    <div>
      <h2 className="text-2xl font-bold text-green-900">Hoşgeldiniz!</h2>
      <p className="text-lg text-green-900">Ben Sigorta Uzmanı Kübra. Uygun kasko teklifi almana son bir adım kaldı!</p>
    </div>
  </div>

        <form onSubmit={handleSubmit} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="tcno">
              T.C. Kimlik Numarası
            </label>
            <input 
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
              id="tcno" 
              type="text" 
              placeholder="T.C. Kimlik Numarası" 
              value={formData.tcno}
              onChange={handleChange}
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="plaka">
              Plaka
            </label>
            <input 
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
              id="plaka" 
              type="text" 
              placeholder="Plaka" 
              value={formData.plaka}
              onChange={handleChange}
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="ruhsat">
              Ruhsat Numarası
            </label>
            <input 
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
              id="ruhsat" 
              type="text" 
              placeholder="Ruhsat Numarası" 
              value={formData.ruhsat}
              onChange={handleChange}
            />
          </div>
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="telefon">
              Telefon Numarası
            </label>
            <input 
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
              id="telefon" 
              type="tel" 
              placeholder="Telefon Numarası" 
              value={formData.telefon}
              onChange={handleChange}
            />
          </div>
          <div className="flex flex-col md:flex-row items-center justify-between gap-4">
  <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
    Teklif Al
  </button>
  <p className="text-sm text-center md:text-left">
    Bu formu göndererek <Link to="/kullanim-kosullari" className="text-red-500 hover:text-red-800">site kullanım koşullarını</Link> kabul etmiş sayılırsınız.
  </p>
</div>
        </form>

        <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
          <div className={`p-4 rounded-lg ${modalContent.isSuccess ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'}`}>
            {modalContent.isSuccess && <CheckCircle className="inline mr-2" />}
            {modalContent.message}
          </div>
        </Modal>

        <div className="mb-8">
          <h3 className="text-2xl font-bold mb-4">Sıkça Sorulan Sorular</h3>
          {FAQs.map((faq, index) => (
            <div key={index} className="mb-4">
              <h4 className="font-bold flex items-center">
                <HelpCircle className="mr-2 text-red-500" />
                {faq.question}
              </h4>
              <p className="ml-6">{faq.answer}</p>
            </div>
          ))}
        </div>

        <div>
          <h3 className="text-2xl font-bold mb-4">Müşteri Yorumları</h3>
          <div className="grid md:grid-cols-3 gap-4">
            {reviews.map((review, index) => (
              <div key={index} className="bg-gray-100 p-4 rounded-lg">
                <p className="mb-2">{review.comment}</p>
                <p className="font-bold">{review.name}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Footer */}
      <footer className="bg-red-600 text-white py-8 px-4">
        <div className="container mx-auto grid md:grid-cols-3 gap-8">
          <div>
            <h3 className="text-xl font-semibold mb-4">Centalya Sigorta Aracılık Hiz. Ltd. Şti.</h3>
            <p>Geleceğinizi güvence altına alıyoruz.</p>
          </div>
          <div>
            <h3 className="text-xl font-semibold mb-4">İletişim</h3>
            <p>Kanal Mah. Yasemin Cad. Nil Apt. no: 7/8 Kepez Antalya</p>
            <p>Telefon: (0242) 331 03 00</p>
            <p>E-posta: info@centalyasigorta.com.tr</p>
          </div>
          <div>
            <h3 className="text-xl font-semibold mb-4">Bizi Takip Edin</h3>
            <div className="flex space-x-4">
              <a href="#" className="hover:text-gray-200">Facebook</a>
              <a href="#" className="hover:text-gray-200">Twitter</a>
              <a href="#" className="hover:text-gray-200">Instagram</a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}