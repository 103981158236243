import React from 'react';
import { Phone, Mail, MapPin, Building, User, FileText } from 'lucide-react';

export default function IletisimSayfasi() {
  return (
    <div className="bg-white min-h-screen">
      <header className="bg-red-600 text-white p-6">
        <h1 className="text-3xl font-bold">İletişim</h1>
      </header>
      <main className="container mx-auto py-12 px-4">
        <div className="grid md:grid-cols-2 gap-12">
          <section>
            <h2 className="text-2xl font-semibold mb-6">Bize Ulaşın</h2>
            <div className="space-y-4">
              <div className="flex items-center">
                <Phone className="w-6 h-6 text-red-600 mr-4 flex-shrink-0" />
                <div>
                  <p>(0242) 331 03 00</p>
                  <p>0553 787 07 07</p>
                  <p>0549 331 03 00</p>
                </div>
              </div>
              <div className="flex items-center">
                <Mail className="w-6 h-6 text-red-600 mr-4 flex-shrink-0" />
                <p>info@centalyasigorta.com.tr</p>
              </div>
              <div className="flex items-start">
                <MapPin className="w-6 h-6 text-red-600 mr-4 flex-shrink-0" />
                <p>Kanal Mah. Yasemin Cad. No:7/B Kepez / ANTALYA</p>
              </div>
            </div>
          </section>
          <section>
            <h2 className="text-2xl font-semibold mb-6">Şirket Bilgileri</h2>
            <div className="space-y-4">
              <div className="flex items-center">
                <Building className="w-6 h-6 text-red-600 mr-4 flex-shrink-0" />
                <p><strong>Ünvanı:</strong> Centalya Sigorta ARC. HİZ. LTD. ŞTİ.</p>
              </div>
              <div className="flex items-center">
                <User className="w-6 h-6 text-red-600 mr-4 flex-shrink-0" />
                <p><strong>Yetkili Kişi:</strong> Fatma Çakır</p>
              </div>
              <div className="flex items-center">
                <FileText className="w-6 h-6 text-red-600 mr-4 flex-shrink-0" />
                <p><strong>TOBB'a Kayıtlı Levha No:</strong> T14114-G6KP</p>
              </div>
            </div>
          </section>
        </div>
        <section className="mt-12">
          <h2 className="text-2xl font-semibold mb-6">Hakkımızda</h2>
          <p className="text-gray-700 leading-relaxed">
            25 yıldır sigorta sektöründe faaliyet gösteren şirketimiz, müşterilerimize en iyi hizmeti sunma misyonuyla çalışmaktadır. Deneyimli ekibimiz ve geniş ürün yelpazemizle, sizin için en uygun sigorta çözümlerini sunuyoruz.
          </p>
        </section>
      </main>
    </div>
  );
}