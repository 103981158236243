import React, { useEffect, useState, useCallback } from 'react';

const AdminPanel = () => {
  const [offers, setOffers] = useState([]);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const fetchOffers = useCallback(async (page) => {
    try {
      const response = await fetch(`/get-all-offers.php?page=${page}`);
      const data = await response.json();
      
      if (data.success) {
        setOffers(data.data);
        setTotalPages(Math.ceil(data.totalCount / data.perPage));
        setError(null);
      } else {
        console.error('API error:', data.error);
        setError(data.error || 'Teklifler alınırken bir hata oluştu.');
        setOffers([]);
      }
    } catch (error) {
      console.error('Teklifler alınırken hata oluştu:', error);
      setError('Teklifler alınırken bir hata oluştu. Lütfen daha sonra tekrar deneyin.');
      setOffers([]);
    }
  }, []);

  useEffect(() => {
    fetchOffers(currentPage);
  }, [currentPage, fetchOffers]);

  const handleStatusChange = async (id, newStatus, offerType) => {
    try {
      const response = await fetch('/update-offer-status.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id, status: newStatus, type: offerType }),
      });
      const result = await response.json();
      if (result.success) {
        setOffers(prevOffers => 
          prevOffers.map(offer => 
            offer.id === id ? { ...offer, status: newStatus } : offer
          )
        );
        setError(null);
      } else {
        console.error('Durum güncellenirken hata oluştu:', result.message);
        setError(`Durum güncellenirken hata oluştu: ${result.message}`);
      }
    } catch (error) {
      console.error('Durum güncellenirken hata oluştu:', error);
      setError(`Durum güncellenirken hata oluştu: ${error.message}`);
    }
  };

  const renderPagination = () => {
    const pages = [];
    for (let i = 1; i <= totalPages; i++) {
      pages.push(
        <button
          key={i}
          onClick={() => setCurrentPage(i)}
          className={`mx-1 px-3 py-1 border rounded ${currentPage === i ? 'bg-blue-500 text-white' : 'bg-white'}`}
        >
          {i}
        </button>
      );
    }
    return pages;
  };

  const getOfferTypeSpecificData = (offer) => {
    switch (offer.type) {
      case 'trafik':
        return {
          plakaOrDate: offer.plaka,
          ruhsatOrAile: offer.ruhsat_no
        };
      case 'saglik':
        return {
          plakaOrDate: offer.dogum_tarihi,
          ruhsatOrAile: offer.aile_police === '1' ? 'Evet' : 'Hayır'
        };
      case 'kasko':
        return {
          plakaOrDate: offer.plaka,
          ruhsatOrAile: offer.ruhsat_no
        };
      default:
        return {
          plakaOrDate: '-',
          ruhsatOrAile: '-'
        };
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Admin Paneli</h1>
      {error && <p className="text-red-500 mb-4">{error}</p>}
      <table className="w-full border-collapse border">
        <thead>
          <tr className="bg-gray-200">
            <th className="border p-2">Teklif Tipi</th>
            <th className="border p-2">TC Kimlik</th>
            <th className="border p-2">Telefon</th>
            <th className="border p-2">Plaka/Doğum Tarihi</th>
            <th className="border p-2">Ruhsat No/Aile Poliçesi</th>
            <th className="border p-2">Durum</th>
            <th className="border p-2">İşlem</th>
          </tr>
        </thead>
        <tbody>
          {offers.map((offer) => {
            const isProcessed = offer.status === 'processed';
            const { plakaOrDate, ruhsatOrAile } = getOfferTypeSpecificData(offer);
            return (
              <tr 
                key={offer.id} 
                className={`${isProcessed ? 'bg-green-100' : 'bg-yellow-100'}`}
              >
                <td className="border p-2">{offer.type}</td>
                <td className="border p-2">{offer.tc_kimlik}</td>
                <td className="border p-2">{offer.telefon}</td>
                <td className="border p-2">{plakaOrDate}</td>
                <td className="border p-2">{ruhsatOrAile}</td>
                <td className="border p-2">{isProcessed ? 'İşlendi' : 'İşlenmedi'}</td>
                <td className="border p-2">
                  <button
                    onClick={() => handleStatusChange(offer.id, isProcessed ? 'pending' : 'processed', offer.type)}
                    className={`p-1 rounded ${isProcessed ? 'bg-red-500' : 'bg-blue-500'} text-white`}
                  >
                    {isProcessed ? 'İşlenmedi Yap' : 'İşlendi Yap'}
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="mt-4 flex justify-center">
        {renderPagination()}
      </div>
    </div>
  );
};

export default AdminPanel;